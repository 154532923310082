// src/components/ContentContainer.js
import styled from 'styled-components';

const ContentContainer = styled.div`
  padding: 20px;
  margin: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  margin: 20px auto; /* Center the content */
`;

export default ContentContainer;
