// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import './styles/Header.css';
import Home from './pages/Home';
import Tips from './pages/Tips';
import Resources from './pages/Resources';

const App = () => {
  return (
    <Router>
      <div>
        <header className="header">
          <div className="overlay"></div>
        </header>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/tips" element={<Tips />} />
          <Route path="/resources" element={<Resources />} />
        </Routes>
        <Footer /> {/* Add Footer here */}
      </div>
    </Router>
  );
};

export default App;
