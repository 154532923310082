// src/pages/Resources.js
import React from 'react';
import ContentContainer from '../components/ContentContainer';

const Resources = () => {
  return (
    <ContentContainer>
      <h2>Resources Page</h2>
      <p>Check out these resources for more information.</p>
    </ContentContainer>
  );
};

export default Resources;
