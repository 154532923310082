// src/pages/Tips.js
import React from 'react';
import ContentContainer from '../components/ContentContainer';

const Tips = () => {
  return (
    <ContentContainer>
      <h2>Tips Page</h2>
      <p>Here are some useful tips for new dads!</p>
    </ContentContainer>
  );
};

export default Tips;
