// src/pages/Home.js
import React from 'react';
import ContentContainer from '../components/ContentContainer';

const Home = () => {
  return (
    <ContentContainer>
      <h2>Home Page</h2>
      <p>Welcome to the Dad Tips home page!</p>
    </ContentContainer>
  );
};

export default Home;
