// src/components/Navbar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <Nav>
      <Brand>
        <h1>Dad Tips</h1>
      </Brand>
      <Hamburger onClick={toggleMenu} open={menuOpen}>
        <span />
        <span />
        <span />
      </Hamburger>
      <Menu open={menuOpen}>
        <MenuItem to="/" onClick={() => setMenuOpen(false)}>Home</MenuItem>
        <MenuItem to="/tips" onClick={() => setMenuOpen(false)}>Tips</MenuItem>
        <MenuItem to="/resources" onClick={() => setMenuOpen(false)}>Resources</MenuItem>
      </Menu>
    </Nav>
  );
};

export default Navbar;

// Styled Components
const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  padding: 15px 20px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10; /* Ensure the navbar is above the hero image */
`;

const Brand = styled.div`
  h1 {
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 2.5rem;
    margin: 0;
  }
`;

const Hamburger = styled.div`
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 25px;
  cursor: pointer;

  span {
    width: 100%;
    height: 3px;
    background: white;
    border-radius: 10px;
    transition: all 0.3s linear;
  }

  ${({ open }) =>
    open &&
    `
      span:nth-of-type(1) {
        transform: rotate(45deg) translate(5px, 5px);
      }
      span:nth-of-type(2) {
        opacity: 0;
      }
      span:nth-of-type(3) {
        transform: rotate(-45deg) translate(5px, -5px);
      }
    `}

  @media (max-width: 768px) {
    display: flex;
  }
`;

const Menu = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-basis: 50%;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
    background: rgba(0, 0, 0, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding-top: 80px;
    transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
    transition: transform 0.3s ease-in-out;
  }
`;

const MenuItem = styled(Link)`
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
  padding: 10px 20px;
  margin: 0 10px;
  border: 2px solid white;
  border-radius: 5px;
  font-weight: 600;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: white;
    color: #4caf50;
  }

  @media (max-width: 768px) {
    margin: 20px 0;
    font-size: 1.2rem;
    width: 80%;
    text-align: center;
  }
`;
