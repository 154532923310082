// src/components/Footer.js
import React from 'react';
import styled from 'styled-components';
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';

const Footer = () => {
  return (
    <FooterContainer>
      <SocialLinks>
        <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
          <FaFacebook />
        </a>
        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
          <FaTwitter />
        </a>
        <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
          <FaInstagram />
        </a>
      </SocialLinks>
      <p>&copy; {new Date().getFullYear()} Dad Tips. All rights reserved.</p>
    </FooterContainer>
  );
};

export default Footer;

// Styled Components
const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #333;
  color: white;
  padding: 20px;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1;
`;

const SocialLinks = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;

  a {
    color: white;
    margin: 0 15px;
    font-size: 24px;
    transition: color 0.3s;

    &:hover {
      color: #4caf50;
    }
  }
`;
